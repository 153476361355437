import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function Home({ data: { strapi: { winningSupportGroup } }, location }) {

	return (
		<>
			<SEO title="Winning Support Group" description={ winningSupportGroup.seoDescription } pathname={ location.pathname } />

			{ winningSupportGroup && winningSupportGroup.content &&
				<DynamicZone components={ winningSupportGroup.content } />
			}
		</>
	)
}

export const query = graphql`
	query winningSupportGroupPageQuery {
		strapi {
			winningSupportGroup {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonStatement {
						statementTitle
						statementSubTitle
					}
					... on StrapiQuery_ComponentProfileProfileList {
						profileListTitle
						profileList {
							id
							profileName
							profileSummary
							profileImage {
								url
								imageFile {
									childImageSharp {
										fluid(maxWidth: 1000) {
											...GatsbyImageSharpFluid_noBase64
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`